
@font-face {
font-family: 'hazelnutReg';
src: local('hazelnutReg'), url(./fonts/TT-Hazelnuts-Regular.otf) format('opentype');
}
@font-face {
font-family: 'hazelnutBla';
src: local('hazelnutBla'), url(./fonts/TT-Hazelnuts-Black.otf) format('opentype');
}
@font-face {
  font-family: "hazelnutBla";
  src: url("https://www.ominimsorpreseria.com/assets/fonts/TT-Hazelnuts-Black.ttf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "hazelnutReg";
  src: url("https://www.ominimsorpreseria.com/assets/fonts/TT-Hazelnuts-Regular.otf");
  font-style: normal;
  font-weight: normal;
}

.App p,.App h1,.App h2,.App h3,.App h4,.App h5,.App h6, label, input, button{
font-family: 'hazelnutReg', sans-serif;
color: #1d1d1b;
margin: 0;
}

.App {
text-align: center;
}
*{
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
section{
overflow: hidden;
background-color: #1d1d1b;
}
.App-header nav {
display: block;
transition: all 0.3s ease-in-out;
/*box-shadow: 0px 2px 28px #797979b0;*/
}
nav .nav-link{
color: rgb(68, 68, 68);
font-weight: 500;
}
nav .nav-link:hover{
  color: #e94567;
}
nav .nav-link:focus{
  color: #e94567;
}
.navClass{
background-color: white;
display: block;
box-shadow: 0px 2px 28px #797979b0;
transition: all 0.3s ease-in-out;
}
nav.navClass .nav-link{
color: rgb(68, 68, 68);
font-weight: 500;
}
nav.navClass .nav-link:hover{
  color: #e94567;
}
nav ul li{
text-decoration: none;
list-style: none;
}
.App-header .container-fluid{
padding: 0 4vw;
}
.navbar-toggler.white{
border: 2px solid white !important;
background-color: transparent;
border: 1px solid transparent;
}
.navbar-toggler:focus{
outline: none !important;
border: 2px solid white !important;
}


.App-header .container-fluid .navbar-brand{
padding: 0;
margin: 0;
width: 12rem;
}
.App-header .container-fluid .navbar-brand img{
width: 65%;
}
.App-header .navbar-expand-lg .navbar-collapse{
justify-content: center;
}
.App-header .navbar-expand-lg .nav-pills {
flex-direction: row;
justify-content: flex-start;
width: 85%;
}
.App-header .navbar-expand-lg .nav-pills .nav-item {
width: auto;
margin: auto 1rem;
}
.mostrar{
display: block !important;
}
.redsocialMob{
display: none;
}
.conteLinked{
display: block;
}
.linkeicon{
font-size: 23px;
}
@media(max-width:991px){
.redsocialMob{
display: block;
}
.conteLinked{
display: none;
}
}
.App .container{
max-width: 105rem;
}

@media (max-width: 1800px){
.App .container {
max-width: 89%;
}
}
.App-header .portada{
height: 128vh;
background-color: white;
background-size: cover;
background-attachment: scroll;
background-position: center;
background-size: cover;
/*display: flex;
justify-content: center;
align-items: center;*/
}
.App-header .portada .container{
height: 100vh;
display: flex;
align-items: center;
padding-top: 40rem;
}
.App-header .portada h1{
font-family: 'hazelnutBla', sans-serif;
margin: 0;
line-height: 5rem;
font-size: 7.4rem;
}
.App-header .portada h4{
font-size: 3.58rem;
margin: 0;
}
.App-header .portada h5{
font-size: 3.6rem;
margin: 0;
}
.App-header .portada p{
font-size: 1.55rem;
}
.App-header .portada a{
text-align: center;
margin-left: 3.5%;
font-size: 1.72rem;
color: #1d1d1b;
text-decoration: none;  
transition: all .3s ease-in-out;
}
.App-header .portada a:hover{
  color: #505050c0;
  text-decoration: none;
  }
.App-header .portada .row{
}
.App-header .portada .col-6{
text-align: left;
}
.App-header .portada .col-6 img{
width: 47%;
margin-top: 4rem;
}


.seccion_uno {
height: auto;
background-image: url(../public/imagenes/historia.jpg) ;
background-repeat: no-repeat;
background-position: bottom;
background-size: cover;
background-position-y: -33rem;
}
.seccion_uno .row{
margin-top: 3rem;
}
.seccion_uno .row .col-3{
text-align: left;
}
.seccion_uno .row .col-3 .conteVideo p{
position: absolute;
margin-top: 2.8rem;
z-index: 9;
margin-left: 3.6%;
font-size: 1.32rem;
}
.seccion_uno .row .col-3 video{
position: absolute;
width: 19%;
z-index: 4;
margin-left: 2rem;
margin-top: 2rem;
border: 33px solid white;
border-top: 55px solid white;
border-radius: 3px;
}
.seccion_uno img {
width: 138%;
z-index: 2;
position: relative;
opacity: .01;
}
.fondoBlanco{
height: 41vw;
background: white;
background-image: url(../public/imagenes/seccion_blanca-01.jpg) ;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
margin-top: 45rem;
}
.seccion_uno .fondoBlanco .container{
padding-top: 3rem;
}
.seccion_uno .fondoBlanco h2{
font-family: 'hazelnutBla', sans-serif;
margin: 0;
line-height: 5rem;
font-size: 7.4rem;
text-align: left;
}
.seccion_uno .fondoBlanco h4{
font-size: 3.58rem;
margin: 0;
text-align: left;
margin-left: 18%;
margin-bottom: -7px;
}
.seccion_uno .fondoBlanco p{
font-size: 1.15rem;
text-align: left;
margin-top: 2rem;
}

.seccion_dos {
height: 45vw;
background-image: url(../public/imagenes/locales.jpg) ;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
margin-top: 3rem;
}
.seccion_dos .conte_swiper{
  overflow: hidden;
  height: 28rem;
  margin-top: 8rem;
  background: white;
}
.swiper-slide{
  overflow: hidden;
}
.swiper-slide img{
width: 109%;
margin-top: 0rem;
} 


.seccion_tres {
height: 41vw;
padding: 3vw 0;
}
.seccion_tres img{
max-width: 48.5%;
margin: auto;
margin-top: 4.8rem;
margin-left: 2.96rem;
}

.seccion_tres .swiffy-slider{
margin: 2rem auto;
}
.seccion_tres .slider-container{
grid-auto-rows: 82% !important;
grid-auto-columns: 39% !important;
grid-gap: 0rem !important;
}
.seccion_tres .slider-container>*{
width: 88% !important;
}
.seccion_tres .slider-container img{
max-width: 70%;
height: auto !important;
}
.seccion_tres .slider-nav-dark .slider-nav {
opacity: .46 !important;
}
.seccion_tres .slider-nav-dark .slider-nav:hover{
opacity: .96 !important;
}
.seccion_tres .slider-nav::after{
width: 1.65rem !important;
height: 1.65rem !important;
background-repeat: no-repeat !important;
}
.seccion_tres .slider-container h4{
margin: 0;
font-size: 0.91rem;
}

.seccion_cuatro {
height: 52vw;
padding:0;
overflow: hidden;
background-image: url(../public/imagenes/sec_contact.jpg) ;
background-repeat: no-repeat;
background-size: cover;
display: flex;
align-items: center;
}
.seccion_cuatro  .col-3{
align-content: center;
background-color: #1548a5;
color:white;
}
.seccion_cuatro .col-3 div h4{
margin-top:-16rem;
font-size: 1.7rem;
color: white;
}

.seccion_cuatro  .col-3 div{
text-align: left;
width: 91%;
margin: auto;
}
.seccion_cuatro  .col-3 div:nth-child(2){
padding-top: 2rem;
}
.seccion_cuatro  .col-3 div p{
margin: 0;
padding: 0;
}
.seccion_cuatro .col-3 div:nth-child(2) p{
padding: 5%;
background-color: #4090d4;
color:white;
transition: all .3s ease-in-out;
}
.seccion_cuatro .col-3 div:nth-child(2) p:hover{
background-color: #2970ab;
color:white;
}
.seccion_cuatro  .col-3 div:nth-child(2) a:nth-child(2) p{
margin: 2rem auto;
}
.seccion_cuatro  .col-3 a{
position: relative;
color:white;
text-decoration: none;
}
.seccion_cuatro  .col-3 svg{
color:white;
font-size: 33px;
padding-right: 0.51rem;
}
.seccion_cuatro .conteTitu{
padding: 5.6rem 0 3rem 0;
}
.seccion_cuatro .title{
font-size: 2.5rem;
color: #1b4d76;
margin-left: 33%;
}
.seccion_cuatro hr{
opacity: .79;
border-bottom: 4px solid #1b4d76;
width: 9%;
margin: 0;
border-radius: 9px;
position: relative;
left: 67%;
}

@media(max-width:1400px){
.seccion_cuatro .col-3 div h4{
margin-top: -21rem; 
}
}
.seccion_cuatro .contForm{
max-width: 89%;
margin: auto;
}
.seccion_cuatro .contForm h4{
text-align: left;
margin-left: 6%;
}
.contForm form{
/*border: 2.5px solid #4181dc5e;
border-radius: 8px;*/
padding: 2rem 0 4rem 0;
text-align: left;
}
.contForm form div.row{
display: flex;
}
.contForm form .row .col-6 div{
display: grid;
border: 1.5px solid #d2d2d294;
border-radius: 4px;
padding: 1rem;
margin: .93rem auto;
width: 80%;
}
.contForm form div{
width: 80%;
margin: 9px auto;
border-bottom: 1px solid rgb(241, 241, 241);
padding: 13px 0;
align-items: end;
display: flex;
}
.contForm form div label{
  text-align: left;
  color: white;
  padding-bottom: .2rem;
  width: 53%;
  margin-left: 3rem;
  text-transform: uppercase;
  align-self: flex-end;
  line-height: 32px;
  font-size: 1.4rem;
}
.contForm form div input{
  padding: 0.61rem 1rem;
  color: white;
  border-radius: 16px;
  outline: 2px solid #1b4d7600;
  transition: all .3s ease-in-out;
  width: 126%;
  height: 3.4rem;
  background: #24262a;
  border: 1px solid #ffffff;
}
.contForm form div input:hover{
outline: 2px solid #1b4d7636;
}
.contForm form div input:focus{
outline: 2px solid #1b4d76;
}
.contForm form div input::placeholder,
.contForm form div textarea::placeholder{
color: #dfdfdfbd;
font-size: 13px;
}
.conteChecks{
margin: 23px auto !important;
margin: 12px auto !important;
}
.conteChecks div{
  width: 100% !important;
  border-bottom: transparent !important;
  margin-bottom: 0px !important;
  padding-bottom: 0 !important;
  margin-left: -8% !important;
}
.conteChecks div label{
width: 29% !important;
margin: 0 !important;
line-height: 38px !important;
}
.conteChecks div input{
width: 4rem !important;
cursor: pointer;
}
.conteChecks div input:nth-child(2){

}
.conteChecks div input:nth-child(4){
margin-left: -4%; 
}
.conteChecks div input:hover{
outline: 2px solid #1b4d7600 !important;
}
.conteChecks div  input:focus{
outline: 2px solid #1b4d7600 !important;
}
.conteChecks.inversion div{
  margin-left: 1% !important;
}
.contForm form .recapcha{
padding: 0;
height: 6rem;
align-items: baseline;
}
.contForm form .recapcha, 
.contForm form .recapcha div {
border: none ;
}
.contForm form .recapcha div {
position: relative;
left: .6rem;
margin: .4rem 0;
}
.contTxtArea{
display: grid;
border: 1.5px solid #d2d2d294;
border-radius: 8px;
padding: 1rem;
margin: .93rem auto;
width: 90%;
}
.contForm form section{
  width: 46%;
}
.contForm form section label{
  width: 82%;
}
.contForm form select{
  border: 1px solid #ffffff;
  padding: 1rem;
  border-radius: 6px;
  outline: none;
  background-color: #24262a;
  color: #9d9ea0;
  cursor: pointer;
  width: 24%;
}
/*.contForm form textarea{
max-width: 100%;
min-width: 100%;
max-height: 15rem;
min-height: 15rem;
height: 215px;
padding: 0.61rem 1rem;
color: #002e70;
border-radius: 4px;
transition: all .3s ease-in-out;
}
.contForm form textarea:hover{
outline: 2px solid #1b4d7636;
}
.contForm form textarea:focus{
outline: 2px solid #1b4d76;
}*/
.contForm form .error{
color: #e13232;
margin: 0;
padding: 0px;
text-align: center;
font-size: 13px;
width:70%;
}
.contForm form .recapcha .error{
margin-left: 4.5%;
}
.contForm form button{
background-color: #e2e3e5;
color: #1d1d1b;
font-weight: 700;
padding: 4px 12px;
border-radius: 9px;
transition: all .3s ease-in-out;
}
.contForm form button:hover{
background-color: #313131;
color: white;
padding: 4px 12px;
}
.contForm form button:focus{
background-color: #3f7ae7;
color: white;
padding: 4px 12px;
}

input[type="radio"] {
display: none;
}
input[type="radio"] + label{
cursor: pointer;
}
input[type="radio"] + label:before{
content: "";
width: 26px;
height: 26px;
float: left;
margin-right: 0.5em;
border: 2px solid grey;
background: #1b4d7636;
margin-top: 0.23em;
}
input[type="radio"] + label:before {
border-radius: 19%;
}

/*input[type="radio"]:checked + label:after {
content: "";
width: 0;
height: 0;
border: 7px solid #0fbf12;
position: absolute;
margin-left: -98px;
margin-top: 14px;
border-radius: 100%;
}*/

input[type="radio"]:checked + label:before{
border-color: grey;
background-color: white;
}


footer{
height: auto;
padding: 5vw 0;
background-color: #232323;
}
.logoFuter{
width: 98%;
}
.navFooter{
text-align: left;
margin-top: -.5rem;
}
.nav-futer{
padding: .84vw 0;
}
footer ul{
padding: 0;
}
footer ul li{
text-decoration: none;
list-style: none;
color: white;
}
footer a{
color: white;
text-decoration: none;
}
footer span{
color: white;
}
.conteDire{

}
.listaDire li{
padding-top:2vw;
}
.conteRsFooter li{
padding-top:2vw;
}
.posfuter {
background-color: #2e2b2be6;
}
.posfuter p {
color: grey;
margin: 0;
padding: 1rem 0;
font-size: 0.7rem;
}

.fraseMobile{
display: none;
}
@media (max-width: 1800px){

  .App-header .portada .container{
    padding-top: 37rem;
  }
  .App-header .portada h1{
    font-size: 6rem;
  }
  .App-header .portada h4 {
    font-size: 2.9rem;
    line-height: 20px;
  }
  .App-header .portada h5 {
    font-size: 2.93rem;
  }
  .App-header .portada p {
    font-size: 1.255rem;
  }
  .App-header .portada .col-6 img {
    width: 49%;
  }
  .App-header .portada a{
    font-size: 1.3rem;
  }
  .seccion_uno {
    background-position-y: -22rem;
    background-position-x: 82%;
    background-size: 100%;
  }
  .seccion_uno .fondoBlanco .container {
    padding-top: 1rem;
  }  
  .seccion_uno .row {
    margin-top: 5rem;
  }
  .seccion_uno .row .col-5{
    margin-top: -3rem;
  }
  .seccion_uno .fondoBlanco h2{
    font-size: 6rem;
  }
  .seccion_uno .fondoBlanco h4{
    font-size: 2.9rem;
    line-height: 45px;
  }
  .seccion_uno .fondoBlanco p{
    font-size: 1.255rem;
    width: 140%;
  }
  .seccion_dos .conte_swiper{
    margin-top: 5rem;
  }
  .seccion_dos .conte_swiper{
    height: 24rem;
  }
  .contForm form section label{
    width: 82%;
  }
}
@media (max-width: 1400px){
  .App-header .portada .container {
    padding-top: 30.4rem;
}
.App-header .portada h1 {
  font-size: 5.33rem;
}
.App-header .portada h4 {
  font-size: 2.59rem;
}
.App-header .portada h5 {
  font-size: 2.63rem;
}
.App-header .portada p {
  font-size: 1.1rem;
}
.App-header .portada .col-6 img {
  width: 45%;
  margin-top: 3rem;
}
.App-header .portada a {
  font-size: 1.095rem;
}
.seccion_uno {
  background-position-y: -8rem;
  background-size: 101%;
}
.seccion_uno .row .col-3 video{
  border: 23px solid white;
  border-top: 55px solid white;
}
.seccion_uno .fondoBlanco h2{
  font-size: 5.33rem;
}
.seccion_uno .fondoBlanco h4{
  font-size: 2.59rem;
  line-height: 32px;
}
.seccion_uno .fondoBlanco p{
  font-size: 1.1rem;
  margin-top: 1rem;
}
.seccion_dos .conte_swiper{
  margin-top:3.8rem;
  height: 21rem;
}
.seccion_tres img {
  max-width: 48.5%;
  margin: auto;
  margin-top: 2.8rem;
  margin-left: 0.96rem;
}
.contForm form div input{
  width: 114%;
}
.contForm form div {
  width: 76%;
  margin: 1px auto;
  padding: 9px 0;
}
.contForm form div label {
  line-height: 11px;
  font-size: 1rem;
}
.contForm form div label b{
  line-height: 30px;
  font-size: .91rem !important;
}
.conteChecks div{
  width: 92% !important;
  padding-top: 0px !important;
}
.contForm form select{
  margin-left: 1rem;
}
.conteChecks.inversion div {
  margin-left: 3% !important;
}
}
@media (max-width: 1200px){
.App-header .portada h1 {
padding: 21px 23px;
font-size: 2rem;
}
.seccion_uno .title,
.seccion_dos .title,
.seccion_tres .title,
.seccion_cuatro .title{
font-size: 1.97rem;
}
.seccion_uno p{
font-size: 1rem;
margin: 1rem auto;
}
.seccion_uno img {
margin: 3rem auto 1rem auto;
}
.seccion_dos h5 {
font-size: 1.2rem;
}
.seccion_dos .conteDos .row:nth-child(1) .col-6:nth-child(1) p,
.seccion_dos .conteDos .row:nth-child(2) .col-6:nth-child(2) p,
.seccion_dos .conteDos .row:nth-child(3) .col-6:nth-child(1) p{
width: 85%;
font-size: 0.8rem;
}
.sliderEnNumeros .slideNumsItem img {
width: 78%;
}
.sliderEnNumeros {
height: 109px;
margin: 3rem auto;
}
.seccion_cuatro .contForm h4 {
font-size: 1.23rem;
} 
.seccion_cuatro .col-3 div h4 {
font-size: 1.23rem;
}
.seccion_cuatro .col-3 div:nth-child(2) p {
font-size: 14px;
}
.seccion_cuatro .col-3 svg {
font-size: 20px;
}
.seccion_cuatro .contForm {
max-width: 85%;
}
.contForm form div label {
font-size: .91rem;
}
.contForm form div input {
padding: 0.41rem .31rem;
}
.contForm form div input::placeholder,
.contForm form div textarea::placeholder{
color: #dfdfdfbd;
font-size: 11px;
}
}
@media (max-width: 1195px){

  .App-header .portada {
    height: 102vh;
  }
  .App-header .portada{
    background-position-x: 65%;
  }
  .App-header .portada .container {
    padding-top: 27.4rem;
  }
  .App-header .portada h1 {
    padding: 0px;
    font-size: 6rem;
  }
  .App-header .portada h4 {
    font-size: 2.9rem;
  }
  .App-header .portada h5 {
    font-size: 2.93rem;
    width: 20rem;
  }
  .App-header .portada p {
    font-size: 1.1rem;
    width: 29rem;
  }
  .App-header .portada .col-6 img {
    width: 16rem;
    margin-top: 6rem;
}
  .App-header .portada p br{
    display: none;
  }
  .App-header .portada .conteWapp {
    width: 21rem;
  }
  .App-header .portada .conteWapp a {
    font-size: 1.095rem;
  }
  .seccion_uno {
    background-position-y: -6rem;
    background-position-x: 61%;
    background-size: 132%;
  }
  .seccion_uno .row .col-3 video {
    border: 9px solid white;
    border-top: 47px solid white;
    width: 25%;
    margin-top: 1rem;
  }
  .seccion_uno .row .col-3 .conteVideo p {
    margin-top: 1.48rem;
    margin-left: 4.86%;
    font-size: 1.12rem;
}
  .fondoBlanco {
    height: 73vw;
    margin-top: 26rem;
    background-position-x: 99%;
}
.seccion_uno .fondoBlanco p {
  font-size: 1.21rem;
  width: 32rem;
}
.seccion_uno .fondoBlanco p br {
  display: none;
}
.seccion_dos{
  height: 80vw;
}
.seccion_dos .conte_swiper{
  height: 22rem;
}
.swiper-slide img{
  width:19rem;
}
.seccion_cuatro{
  height: 92vw;
}

}
@media (max-width:990px){
  nav .nav-link{
    text-align: left;
    }
    .App-header .navbar-expand-lg .nav-pills {
    flex-direction: column  ;
    }
}
@media (max-width: 770px){
  .App .container {
    max-width: 97%;
}
  .App-header .portada {
    background-position-x: 11%;
}
.App-header .portada .container {
  padding-top: 9.4rem;
}
  .App-header .portada .row {
    flex-direction: column;
  }
  .App-header .portada .row .col-6{
    max-width: 100%;
    width: 100%;
  }
  .App-header .portada h1 {
    padding: 0px;
    font-size: 4.6rem;
}
.App-header .portada h4 {
  font-size: 2.19rem;
}
.App-header .portada h5 {
  font-size: 2.3rem;
}
.App-header .portada p {
  font-size: 1.1rem;
  text-shadow: 0px 0px 3px #ffffff;
  width: 100%;
}
.App-header .portada p br{
  display: none;
}
.App-header .portada .col-6 img {
  width: 16rem;
  margin-top: 1rem;
} 
.seccion_uno {
  height: auto;
  background-image: url(../public/imagenes/historia_mobile.jpg) ;
  background-size: cover;
  background-position-y: -20rem;
  background-position-x: 3%;
  }
.seccion_uno .row .col-3 video {
  position: relative;
  border: 11px solid white;
  border-top: 36px solid white;
  width: 12rem;
  height: 4%;
  margin-left: 2rem;
  margin-top: -3rem;
}
.seccion_uno .row .col-3 .conteVideo p {
  position: absolute;
  margin-top: -2.72rem;
  z-index: 9;
  margin-left: 3rem;
  font-size: 1rem;
}
.seccion_uno .fondoBlanco{
  height: auto;
  padding-bottom: 4rem;
  background-position-x: 40%;
  background-position-y: 101%;
  background-size: 103%;
  margin-top: 1rem;
} 
.seccion_uno .fondoBlanco .row{
  flex-direction: column;
}
.seccion_uno .fondoBlanco .row .col-5{
  max-width: 100%;
  width: 100%;
}
.seccion_uno .fondoBlanco h2{
  padding: 0px;
  font-size: 4.6rem;
}
.seccion_uno .fondoBlanco h4{
font-size: 2.19rem;
}
.seccion_uno .fondoBlanco p {
  font-size: 1.1rem;
  text-shadow: 0px 0px 3px #ffffff;
  width: 100%;
}
.seccion_uno .fondoBlanco p br{
  display: none;
}
.seccion_dos {
  height: 71vh;
  background-position-y: 3rem;
  background-position-x: -26rem;
  background-position-x: 51%;
}
.seccion_dos .conte_swiper{
  margin-top:4rem;
  height: 20rem;
}
.swiper-slide img{
  width: 15rem;
  margin-top: 0rem;
  } 
.seccion_tres {
  height: 50vh;
  padding: 3vw 0;
}
.seccion_tres img {
  max-width: 90%;
  margin: auto;
  margin-top: 4.8rem;
  margin-left: 1%;
}
.seccion_cuatro {
  height: auto;
  padding: 2rem 0 7rem 0;
  background-position-y: 1rem;
  background-position-x: -41rem;
  background-position-x: 51%;
}
.seccion_cuatro .contForm {
  max-width: 100%;
}
.contForm form div {
  width: 100%;
  margin: 1px auto;
  padding: 9px 0;
}
.contForm form div label {
  font-size: .91rem;
  line-height: 21px;
  margin-left: 0rem;
}
.contForm form div input {
  width: 146%;
}
.conteChecks {
  margin: 19px auto !important;
  align-items: center !important;
}
.conteChecks div {
  width: 118% !important;
  margin-left: -1% !important;
}
.conteChecks div input {
  width: 11rem !important;
}
.conteChecks div label {
  width: 81% !important;
} 
.contForm form select{
  width: 60%;
  padding: .3rem;
  font-size: .8rem;
}
.contForm form select {
  margin-left: 0rem;
}
.contForm form div label b {
  font-size: .82rem !important;
  line-height: 20px;
}
}
@media (max-width: 350px){
  .App-header .portada {
    height: 117vh;
    background-position-x: 11%;
}
.App-header .portada .container {
  padding-top: 14.4rem;
}
.App-header .portada h1 {
  padding: 0px;
  font-size: 3.6rem;
  line-height: 50px;
}
.App-header .portada h4 {
  font-size: 1.9rem;
}
.App-header .portada h5 {
  font-size: 1.73rem;
}
.App-header .portada p {
  font-size: 0.9rem;
  width: 90%;
}
.App-header .portada .col-6 img {
  width: 14rem;
  margin-top: 1rem;
}
.App-header .portada a {
  font-size: 1rem;
}
.seccion_uno .fondoBlanco h2 {
  padding: 0px;
  font-size: 3.6rem;
}
.seccion_uno .fondoBlanco h4 {
  font-size: 1.9rem;
}
.seccion_uno .fondoBlanco p {
  font-size: 0.9rem;
}
.seccion_dos {
  height: 96vh;
  background-position-y: 3rem;
  background-position-x: -24rem;
}
.seccion_tres {
  height: 75vh;
  padding: 3vw 0;
}
.contForm form div label {
  font-size: .8rem;
}
.contForm form div label b {
  line-height: 20px;
  font-size: .81rem !important;
}
.contForm form .recapcha div {
  left: -0.14rem;
  margin: 0rem 0 -1rem 0;
}
}